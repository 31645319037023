import { ApplicationRequest } from '@xy/package-backend-request';

export default ({ store }) =>
  store.registerModule(['global', 'token'], {
    namespaced: true,
    state: { token: '' },
    mutations: {
      token: (state, token) => {
        state.token = token;
      }
    },
    actions: {
      token: (context, token) => {
        // 需要在将登录结果写入vuex之前, 注册private api axios
        ApplicationRequest.initRequestParams();
        ApplicationRequest.registerPrivateRequest(token);
        context.commit('token', token);

        // 供action调用方使用
        return token;
      }
    }
  });
