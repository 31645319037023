export default ({ store }) =>
  store.registerModule(['global', 'permission'], {
    namespaced: true,
    state: { permissions: [], hasOpenExt: false, hasClda: false },
    mutations: {
      setPermissions: (state, permissions) => {
        state.permissions = permissions;
      },
      setHasOpenExt: (state, hasOpenExt) => {
        state.hasOpenExt = hasOpenExt;
      },
      setHasClda: (state, permissions) => {
        state.hasClda = permissions;
      }
    }
  });
