import { executor } from '@/utils/require';

export default payload => {
  payload.store.registerModule('page', {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {},
    getters: {}
  });

  // 递归加载应用页面目录下所有.puex.js文件, 各.puex.js文件作为page的子模块入口, 注册自身
  executor(require.context('@/views', true, /\.puex\.js$/), payload);
};
