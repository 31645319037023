import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('global/token');

export default class {
  static get tokenMixin() {
    return {
      computed: mapState({
        token: state => state.token
      }),
      methods: mapActions({
        setToken: 'token'
      })
    };
  }
}
