/*
 * @Author: 王星
 * @Date: 2021-07-09 10:47:04
 * @LastEditors: 王星
 * @LastEditTime: 2021-07-09 10:48:05
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const getRoutes = () => [
  {
    path: '/',
    redirect: 'mainpage/homepage'
  },
  {
    path: '/mainpage',
    redirect: 'mainpage/productsystem',
    component: () => import(/* webpackChunkName: 'MainPage' */ '@/views/frame-home/MainPage'),
    children: [
      {
        path: 'newCenter',
        name: 'newCenter',
        component: () => import(/* webpackChunkName: 'newCenter' */ '@/views/frame-home/newCenter/NewCenter.vue')
      },
      {
        path: 'newsDetail',
        name: 'newsDetail',
        component: () =>
          import(/* webpackChunkName: 'newsDetail' */ '@/views/frame-home/newCenter/components/NewsDetail.vue')
      },
      {
        path: 'homepage',
        component: () => import(/* webpackChunkName: 'homepage' */ '@/views/frame-home/homepage/HomePage.vue')
      },
      {
        path: 'productsystem',
        name: 'productsystem',
        component: () =>
          import(/* webpackChunkName: 'ProductSystemPage' */ '@/views/frame-home/ProductSystem/ProductSystemPage.vue')
      },
      {
        path: 'productsystem/:id',
        component: () =>
          import(/* webpackChunkName: 'productsystem' */ '@/views/frame-home/ProductSystem/ProductSystemPage.vue')
      },
      {
        path: 'visual-center/algorithm-factory',
        name: 'factory',
        component: () =>
          import(
            /* webpackChunkName: 'AlgorithmFactoryPage' */ '@/views/frame-home/AlgorithmFactory/AlgorithmFactoryPage.vue'
          )
      },
      {
        path: 'visual-center/visual-center',
        name: 'visual',
        component: () =>
          import(/* webpackChunkName: 'visualCenter' */ '@/views/frame-home/visual-Center/visualCenter.vue')
        // import(
        //   /* webpackChunkName: 'AlgorithmFactoryPage' */ '@/views/frame-home/AlgorithmFactory/AlgorithmFactoryPage.vue'
        // )
      },
      {
        path: 'visual-center/ai-city',
        name: 'city',
        component: () => import(/* webpackChunkName: 'ai-city' */ '@/views/frame-home/ai-city/AiCity.vue')
      },
      {
        path: 'visual-center/ai-industry',
        name: 'industry',
        component: () => import(/* webpackChunkName: 'ai-industry' */ '@/views/frame-home/ai-industry/IndustryPage.vue')
      },
      {
        path: 'technology/technology-artificial',
        name: 'technology',
        component: () =>
          import(
            /* webpackChunkName: 'technology-artificial' */ '@/views/frame-home/technology-core/ArtificialTechnology.vue'
          )
      },
      {
        path: 'technology/technology-bigdata',
        component: () =>
          import(
            /* webpackChunkName: 'technology-bigdata' */ '@/views/frame-home/technology-core/BigdataTechnology.vue'
          )
      },
      {
        path: 'technology/technology-video',
        component: () =>
          import(/* webpackChunkName: 'technology-video' */ '@/views/frame-home/technology-core/VideoTechnology.vue')
      },
      {
        path: 'about',
        component: () =>
          import(/* webpackChunkName: 'ai-city' */ '@/views/frame-home/company-introduce/CompanyIntroduce.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import(/* webpackChunkName: 'NotFound' */ '../views/common/NotFound.vue')
  }
];

const router = new VueRouter({
  routes: getRoutes()
});

export default router;
