import Vue from 'vue';

import App from './App.vue';
import { init } from './setup';
import ElementUI from 'element-ui';
import Global from './utils/global';
import '@xy/package-css-manager';
import './assets/font/iconfont.css';
import './assets/iconfont/iconfont.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible/flexible';

import VueAwesomeSwiper from 'vue-awesome-swiper';

// import style
import 'swiper/css/swiper.css';

import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(ElementUI);

const config = init();
Global.setGlobal(config); // 在Vue实例创建前设置Global

const root = new Vue({
  ...config,
  render: h => h(App)
}).$mount('#app');

Global.root = root;
