import { executor } from '@/utils/require';

export default payload => {
  payload.store.registerModule('global', {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {},
    getters: {}
  });

  // 加载其下子模块
  executor(require.context('./global', false, /\.vuex\.js$/), payload);
};
