let _root = null;
let _store = null;
let _router = null;
let _i18n = null;

class Global {
  static setGlobal({ root, router, store, i18n }) {
    _root = root;
    _router = router;
    _store = store;
    _i18n = i18n;
  }

  static set root(root) {
    _root = root;
  }

  static get root() {
    return _root;
  }

  static set store(store) {
    _store = store;
  }

  static get store() {
    return _store;
  }

  static set router(router) {
    _router = router;
  }

  static get router() {
    return _router;
  }

  static set i18n(i18n) {
    _i18n = i18n;
  }

  static get i18n() {
    return _i18n;
  }
}

export default Global;
