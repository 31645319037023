<template>
  <div id="app" :class="$style.app">
    <loading-wrapper :loading="loading">
      <router-view />
    </loading-wrapper>
  </div>
</template>

<script>
import { init, removeMessageEvent, controlFrameworkSystem, registerTokenHandler } from '@xy/uidp-frame-client';
import { LoadingWrapper } from '@xy/ui-loading';
import { ApplicationEnv } from '@xy/package-environment-config';
import TokenMixins from './mixins/global/token/TokenMixins';
export default {
  components: {
    LoadingWrapper
  },
  mixins: [TokenMixins.tokenMixin],
  data() {
    return {
      loading: false,
      menus: [],
      baseSize: 16
    };
  },
  mounted() {
    // 初始化通信框架
    init();
    if (ApplicationEnv.production) {
      // 请求token后的响应处理器
      registerTokenHandler(this.tokenHandler);
    } else {
      const token =
        'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJhZG1pbmlzdHJhdG9yIiwidXNlck5hbWUiOiJhZG1pbmlzdHJhdG9yIiwidXNlckFsaWFzIjoi566h55CG5ZGYIiwib3JnSWQiOiIzMTAwMDAwMDAwMDAiLCJvcmdOYW1lIjoi5LiK5rW35biC5YWs5a6J5bGAIiwiZXhwIjoxNjIyOTY0ODEwfQ.Y8vmNME6e0C3Ul6X14drA-AsyMM6aikBRnP8ExsJO8A';
      this.tokenHandler({
        data: {
          payload: {
            token
          }
        }
      });
    }
    window.addEventListener('orientationchange' in window ? 'orientationchange' : 'resize', this.renderResize, false);
  },
  beforeDestroy() {
    //  必须进行事件清理
    removeMessageEvent();
    window.removeEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      this.renderResize,
      false
    );
  },
  methods: {
    renderResize() {
      if (window.orientation == 90 || window.orientation == -90) {
        var fromShu = window.sessionStorage.getItem('name');
        if (fromShu === 'a') {
          window.location.reload(true);
          window.sessionStorage.setItem('name', 'b');
        }
        return false;
      } else if (window.orientation == 0 || window.orientation == 180) {
        var fromHeng = window.sessionStorage.getItem('name');
        if (fromHeng === 'b') {
          window.location.reload(true);
        }
        window.sessionStorage.setItem('name', 'a');
        return false;
      }
    },
    tokenHandler(event) {
      const { token } = event.data.payload;
      this.setToken(token);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/common';
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:global {
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}
.app {
  width: 100%;
  height: 100%;
}
html,
body {
  overflow: auto !important;
  @include root-font-size();
}
</style>
